<template>
  <div>
    <vx-card class="mb-8">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-checkbox v-model="checked" class="ml-0">Enable daily contact download limits per user.</vs-checkbox>
          <div class="flex items-center pt-4 pb-2 flex-wrap" v-if="checked">
            <div>Limit each user to</div>
            <vs-input
              class="inputx mx-2"
              placeholder="limit"
              v-model="value1"
            />
            <div>contact downloads per day.</div>
          </div>
        </div>
      </div>
    </vx-card>
    <vx-card class="mt-8">
      <div class="card-title">
        <h2>Usage Audit Log</h2>
      </div>
      <div class="common-table">
        <div class="table-row-select">
          <vs-select v-model="perPage" class="show-select" autocomplete>
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in recordList"
            />
          </vs-select>
        </div>
        <vs-table search :data="users" :max-items="perPage" pagination>
          <template slot="thead">
            <vs-th sort-key="date">Date</vs-th>
            <vs-th sort-key="username">User</vs-th>
            <vs-th sort-key="name">Contact</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].date">{{ data[indextr].date }}</vs-td>
              <vs-td :data="data[indextr].username">{{ data[indextr].username }}</vs-td>
              <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

/* Filter */
import vSelect from "vue-select";

export default {
  components: {
    VxCard,
    vSelect,
  },
  data() {
    return {
      perPage: 5,
      recordList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
      checked: false,
      users: [
        {
          name: "Leanne Graham",
          username: "Bret",
          date: "12/21/2020 8:43:52 AM",
        },
        {
          name: "Leanne Graham",
          username: "Bret",
          date: "12/21/2020 8:43:52 AM",
        },
        {
          name: "Leanne Graham",
          username: "Bret",
          date: "12/21/2020 8:43:52 AM",
        },
        {
          name: "Leanne Graham",
          username: "Bret",
          date: "12/21/2020 8:43:52 AM",
        },
        {
          name: "Leanne Graham",
          username: "Bret",
          date: "12/21/2020 8:43:52 AM",
        },
        {
          name: "Leanne Graham",
          username: "Bret",
          date: "12/21/2020 8:43:52 AM",
        },
        {
          name: "Leanne Graham",
          username: "Bret",
          date: "12/21/2020 8:43:52 AM",
        },
        {
          name: "Leanne Graham",
          username: "Bret",
          date: "12/21/2020 8:43:52 AM",
        },
        {
          name: "Leanne Graham",
          username: "Bret",
          date: "12/21/2020 8:43:52 AM",
        },
        {
          name: "Leanne Graham",
          username: "Bret",
          date: "12/21/2020 8:43:52 AM",
        },
      ],
    };
  },
};
</script>
